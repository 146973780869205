import CryptoJS from 'crypto-js'
 import JSEncrypt from "./jsencrypt/jsencrypt.js";

//  console.log('加密', CryptoJS )
// 存储搜索关键词到本地
export function storeSearchKeywordsLocally(val = ''){
    // 获取所有搜索过的关键词
    let arr = getAllLocalSearchKeywords()
    // 如果关键词已存在则不存入本地
    if(arr.includes(val)) return
    // 数据存入本地
    localStorage.setItem("search_keyword", JSON.stringify([...arr, val]));
}

//判断访问终端
export function terminalType() {
    let u = navigator.userAgent, app = navigator.appVersion;
    return {
        trident: u.indexOf('Trident') > -1, //IE内核
        presto: u.indexOf('Presto') > -1, //opera内核
        webKit: u.indexOf('AppleWebKit') > -1, //苹果、谷歌内核
        gecko: u.indexOf('Gecko') > -1 && u.indexOf('KHTML') == -1,//火狐内核
        mobile: !!u.match(/AppleWebKit.*Mobile.*/), //是否为移动终端
        ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), //ios终端
        android: u.indexOf('Android') > -1 || u.indexOf('Adr') > -1, //android终端
        iPhone: u.indexOf('iPhone') > -1, //是否为iPhone或者QQHD浏览器
        iPad: u.indexOf('iPad') > -1, //是否iPad
        webApp: u.indexOf('Safari') == -1, //是否web应该程序，没有头部与底部
        weixin: u.indexOf('MicroMessenger') > -1, //是否微信
        qq: u.match(/\sQQ/i) == " qq" //是否QQ
    };
}

// 获取本地所有搜索关键词
export function getAllLocalSearchKeywords(){
    const storedNames = JSON.parse(localStorage.getItem("search_keyword"));
    if (storedNames){
        return storedNames
    }
    return []
}

export function getUserInfo(info = {}, callBack = () => {}){
    let userInfo = this.$http.userReq.getUserInfo.bind(this)
    userInfo().then(res => {
        if(res.status != 200) {
            callBack()
            this.$toast('数据获取失败')
            return
        }

        if (res.data.code != 200) {
            callBack()
            this.$toast(res.data.message)
            return
        }

        let data = info
        data.user = res.data.data
        this.$store.commit("config/setCDN",res.data.cdns)
        this.$store.commit('user/setLoginInfo', data)
        this.$store.commit('user/setLoginStatus', true)
        this.$store.commit('common/setLoginShow', false)
        callBack()
    }).catch(err => {
        callBack()
        this.$toast('数据获取失败')
    })
}

export function sortObjByKey (obj) {
    let keys = Object.keys(obj).sort();
    let newObj = {}
    for(let i = 0; i < keys.length; i++){
        let index = keys[i];
        newObj[index] = obj[index];
    }
    return newObj;
}

/**
 * AES 解密
 * @param ciphertext 密文
 * @param key 加密 key
 * @param iv 偏移量
 * @returns 明文
 */
export function decrypt(key, iv, data) {
    key = CryptoJS.enc.Utf8.parse(key)
    iv = CryptoJS.enc.Utf8.parse(iv)
    const decryptedData = CryptoJS.AES.decrypt(data, key, {
        iv,
        padding: CryptoJS.pad.Pkcs7
    })
    return decryptedData.toString(CryptoJS.enc.Utf8)
}

/**
 * 加密方法
 * @param key 加密key
 * @param iv       向量
 * @param data     需要加密的数据
 * @returns string
 */
export function encrypt(key, iv, data) {
    key = CryptoJS.enc.Utf8.parse(key)
    iv = CryptoJS.enc.Utf8.parse(iv)
    const encryptedData = CryptoJS.AES.encrypt(data, key, {
        iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    })
    return encryptedData.toString()
}

export function randomString(length) {
    let str = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ+_-*?';
    let result = '';
    for (let i = length; i > 0; --i)
        result += str[Math.floor(Math.random() * str.length)];
    return result;
}

export function rasEncrypt(str) {
    var encryptor = new JSEncrypt()
    var pubKey = `-----BEGIN PUBLIC KEY-----
    MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAus08yaWjnh8lZeCy09vF
    6zKo80VN0cEwkfbNtrrx+QPJLTxdBsduetguV8xWU3zRMfO1Yn5o+bhMr1+hkWq1
    yIDbVnJwuikg4C9SRfsi/tYl+3V2r8n+F3xLkN8B6AEsuMtJaD1sHoTa6whLb+OX
    p+IpRTCqxUQbObvjbXtpopTKykyw8ox3C5b+RdYla9OLa0dTqpYzEwCr2Nz/n5K5
    P+x1KJwFMN/VEmG0Q9Nxbv8O6vfa5nueO7o9pUHV4h5JIRZGO7kMXeXwRYUNvdzM
    TQ25PEDKMS/mqCELX6keS4psQM+HzNaLsdvGzkrEEdyqY+MhBKqg8vb+Dy7xsZ1A
    BwIDAQAB
    -----END PUBLIC KEY-----`
    encryptor.setPublicKey(pubKey)//设置公钥
    return encryptor.encrypt(str)  // 对内容进行加密
}

export function rasDecrypt(str) {
    var decrypt = new JSEncrypt()//创建解密对象实例
    var priKey  =  `
    -----BEGIN PRIVATE KEY-----
    MIIEvQIBADANBgkqhkiG9w0BAQEFAASCBKcwggSjAgEAAoIBAQCtfv9x4fKI3Uik
    bjk7JhVxXtXztNOgrnJFnlwOmnjbnsBxaeK3xWHKw89/g1YzUjaqqjNZi6UzzuNP
    /3gmBZEqWU/9GmEUAj2Fek7AvcFr8XVsbHynsM3CyDhIFA1C6pwA7gB5MN3OWs0C
    bdBpAsrOAUGTpuG/azXaTm44GCbj6O05xQiq7KxLxWHirmenZcdFxZSm1CYoUftQ
    fDRdo3ztASMzK1tZvH2Biu3WFnZ6t6XjApX8U4DD02JRZQ3kHsKmzZzVS148kBsb
    gRTqdpKWfn5KNAozaqcAoyx3wlEaC9q5yLE8issod02gc2pea0Y/JmMx0pHqZSDu
    VSkD508BAgMBAAECggEBAIVjCF20C1WyW3Ef1RxmkUHSWce+lJ4KwIxJzhjkJlsj
    WG1ZrRcAx8iuds9gWFuor6LSrBwCQwbr3tPYY7VHvm3CsL61E0Hg3w/KEtYYjabB
    wzeSyNvcELXaXXr8/50c5sfIM9D5Au7t3IQrjXnOzcFIMfSk7xbOBF9MwWybFPtP
    CznwqfDZJPKkMqbRJ4JSDAxwUM3E/8b5iS8Py4JoaYWE30x2ntw2MIJ0xSMBMEeY
    NfseLZ14WGp4/Bw6WTu5xMS6SRR8ZBJhoraYAdDuM7+jm2jLIGNf8s0KE/xKXq1X
    sqHzwpYftN49qzcCVKw1VGb0WZj7e//ZHjKum0dneekCgYEA2CeXwoyeLYkKqF7Q
    aNU8ZLqoXVQiCzrfL9sK+l26sYzXPA1PHOJn1DGlk1gX4yt1ifGPyoysn6xCEWp6
    dnq27IQEDiemIGnt1ARL3TMfB4H+EoF/ngs4BigYX0qKh6OF1f65fLcyVs5jthoH
    kszczR7031/whUbAY0w+kjj3HfsCgYEAzXpVVNMbCcBgUigjyx8jPtfpzXBwiPT7
    S0m+HbqrPwuBey9NApxAoRkoxwdjG3huK/tDf8PMxbPYX2/hsVLFT2GKK6kDtesS
    7FD7mXxicXQVoMEPgTBbFJ8f/PmGOBVGihUhIJ1v5GS2g8xN9/3Jh2vvDjk9ppmt
    9QElPgBDIjMCfwhxg3wWAhfFQqVRDtMJgVJt+CWtI6dMpWs5IzSkdKZ62e1+l5iJ
    P4UKa21WA5jcGbtJtFQ1KokSMYJ8pkv6fyq9/WqQIe0iNigSAuShGpUwfrw6UsHZ
    rlaPOmR+hOrHeHLjgFtezJiXd02ltqYFWpqomuge10ZdfSZj1A3iK8UCgYEAlJW0
    5OzvLWMGhAJwt65hj00UzbFUP0Vf/nZp2xekQ6evTwPKXWveNGB8I0hNO40fpgBB
    ND/DcOd3PLQr8U1YTunKv32tqDw/59E7zyaqDT6TcTaKXrI3+raCrC1ICIAiDTHw
    TaRrSC4n6TCw5PaJZ7r+GDv+P937dCMK6kXIRg8CgYEAqgq0vrJ1jtrGP15CgkN7
    gS+ZUGV7ZDaqYABWS1ZMK7wY7Bh/jYo+w30wT+xu1n9u1wu88KJ5E2AXU7FL0MBO
    W4IC/x8a1LlqzzTod9uzk1VT/QkVqgX3OvGY4YrS/1jYsPHS5yF878A+7NGv25iX
    esbc68SRtYek37cDRMFFIoc=
    -----END PRIVATE KEY-----
    `;
    decrypt.setPrivateKey(priKey)//设置秘钥
    return decrypt.decrypt(str)//解密之前拿公钥加密的内容
}

export function getUrlKey(key) {
    return decodeURIComponent((new RegExp('[?|&]' + key + '=' + '([^&;]+?)(&|#|;|$)').exec(location.href) || [, ""])[1].replace(/\+/g, '%20')) || null
  }
