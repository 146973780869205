
import axios from 'axios';
import qs from 'qs';
import md5 from 'js-md5';

import { sortObjByKey, randomString, encrypt, decrypt, rasEncrypt, rasDecrypt } from '@/common/common'


axios.defaults.timeout = 30 * 1000;
const retry = 3;
const url =  process.env.NODE_ENV_BASEURL;
const domain =`${url}/pwa/user/recordAppointment`;

axios.interceptors.request.use(config => {
  let BaseDate = {
    timestamp: new Date().getTime(),
  }
  config.headers = {
    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
  };
  config.headers.token = localStorage.getItem('token') ? localStorage.getItem('token') : '';
  if (!config.url.includes('.webp')) {
    config.url = `${config.url}?pathParams=key`;
  }
  let fIV = "8597506029392492";
  let fKey = "l*bv%ZiqBiaogaKm";
  let arr = ["common", 'delete', 'get', 'head', 'patch', 'post', 'put']
  let head = {}
  for (const arrKey in config.headers) {
    if (!arr.includes(arrKey)) {
      head[arrKey] = config.headers[arrKey]
    }
  }
  let key = JSON.stringify({
    key: fKey,
    iv: fIV,
  })
  let k = rasEncrypt(key)
  if (config.method == 'post') {
    if (config.data) {
      let json = qs.parse(config.data)
      json = JSON.stringify({ ...json })
      config.data = qs.stringify({ bodyParams: encrypt(fKey, fIV, json), bodyKey: k })
    }
  }
  config.headers = {
    ...config.headers,
    k,
    d: encrypt(key, fIV, JSON.stringify({
      ...head,
      s: md5(JSON.stringify(sortObjByKey({ ...head, k: fKey })))
    })),
  }

  // config.data = { ...config.data, ...BaseDate }
  return config;
}, error => {
  return Promise.reject(error);
});

// 响应拦截器
axios.interceptors.response.use(response => {
  if (response.data.code == 0) {
    // const { code } = response.data;
    let { info, info_key } = response.data
    let code = response.data.code
    let msg = response.data.msg
    let timestamp = response.data.timestamp
    if (info_key) {
      let key = rasDecrypt(info_key)
      let KKEY = key.split(',')[0].split(':')[1].replace('"', "").replace('"', "")
      let KIV = key.split(',')[1].split(':')[1].replace('"', "").replace('"', "").replace('}', "")
      // response.data = JSON.parse(decrypt(key, conf.api.IV, d))
      // console.log(decrypt(KKEY, KIV, info))
      response.data.info = JSON.parse(decrypt(KKEY, KIV, info))
      response.data.code = code
      response.data.msg = msg
      response.data.timestamp = timestamp
    }
    return response
    // if (code == 2000) {
    //   // Toast('用户登录异常，请重新登录');
    // }
  } else {
    return response;
  }

}, error => {
  console.log(error)

  // if(error.response.data) {
  //   return Promise.reject({type: "error", msg: error.response.data})
  // }
  let retryCount = 0;
  // 请求> 设置好的请求次数，不再发送请求， 返回错误信息
  if (retryCount >= retry) {
    if (error.message == 'Network Error') {
      return Promise.reject({ type: "warning", msg: "网络故障，稍后再试" });
    }
    else if (error.message == 'timeout of 30000ms exceeded') {
      return Promise.reject({ type: "warning", msg: "请求超时，稍后再试" });
    }
  }

  if (error.message == 'Network Error') {
    // retryCount += 1;
    // tautology(error);
  }
  if (error.message == 'timeout of 30000ms exceeded') {
    // retryCount += 1;
    // tautology(error);
  }


});

export function get(url, params, header = {}, prefix = true) {
  return new Promise((resolve, reject) => {
    axios.get(prefix ? `${domain}${url}` : `${url}`, {
      params: params
    })
      .then(response => {
        resolve(response);
      })
      .catch(err => {
        reject(err)
      })
  })
}

// 封装json格式
export function post(data) {
  return new Promise((resolve, reject) => {
    axios.post(`${domain}`, data).then(response => {
      resolve(response);
    }, err => {
      reject(err)
    })
  })
}
// 封装表单格式数据
export function post2(data) {
  return new Promise((resolve, reject) => {
    axios.post(`${domain}`, data).then(response => {
      resolve(response);
    }, err => {
      reject(err)
    })
  })
}

// 重新请求
function tautology(err) {
  let back = new Promise(function (resolve) {
    console.log('接口失败重新请求', err.config.url);
    resolve();
  })
  return back.then(function () {
    return axios(err.config);
  })
}
