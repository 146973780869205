<template>
  <div class="home">
    <img class="bj" src="../assets/images/bj.jpg" alt />
    <div class="main">
      <img class="start" src="../assets/images/start.png" alt />
      <div class="start_time">
        <img class="time_bj" src="../assets/images/time.png" alt />
        <div class="pickedTime">
          <template v-if="!isReadyLive">
            <span class="times days">{{ getTwo(days) }}</span>
            <p>天</p>
            <span class="times hours">{{ getTwo(hours) }}</span>
            <p>时</p>
            <span class="times minutes">{{ getTwo(minutes) }}</span>
            <p>分</p>
            <span class="times seconds">{{ getTwo(seconds) }}</span>
            <p>秒</p>
          </template>
          <template v-if="isReadyLive">
            <p
              style="padding: 11px 0;margin: 0;"
            >{{ isdomainLive ? '直播即将开始，请重启App观看' : '直播即将开始，请开启one app观看' }}</p>
          </template>
        </div>
      </div>

      <img class="girl" src="../assets/images/girl.png" alt />
      <img class="girl_name" src="../assets/images/name.png" alt />

      <div class="reserve">
        <img class="text_sm" src="../assets/images/text.png" alt />
        <div class="ctn" @click="handDialogClick()" :class="active1 ? 'active' : ''">
          <img
            class="reserve_text"
            :src="active1 ? require('../assets/images/reserve_010.png') : require('../assets/images/reserve_01.png')"
          />
        </div>
        <div class="number">{{ NumberPeo }}人已预约</div>
      </div>
    </div>

    <van-popup v-model="show" closeable :style="{ width: '100%', height: '100%' }">
      <div class="dialogBody" ref="ImageContent">
        <div class="box">
          <div class="title" v-if="isShowBtn">预约成功</div>
          <p class="title_text" v-if="isShowBtn">分享给好友，精彩内容一起看</p>
        </div>
        <div class="dialogBox">
          <img class="av_bj" src="../assets/images/girl_bj.png" alt />
          <p class="text_1">2023 ONE一个神秘之夜</p>
          <div class="live">
            <button class="live_text">直播</button>2023年07月12日 21:00
          </div>
          <div class="code_ctn">
            <div class="left">
              <div class="name">
                您的直播票
                <span class="number">No.{{ dataObj.iNum }}</span>
              </div>
              <p class="text_2">长按识别二维码，一起看直播</p>
            </div>
            <div class="code">
              <img src="../assets/images/code.png" alt />
            </div>
          </div>
        </div>
        <div class="footer" v-if="isShowBtn">
          <button class="cope_text" @click="handCopyClick()">复制</button>
          <button class="cope_text savePhoto" @click="handSaveImage()">截屏保存</button>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
// @ is an alias to /src

import APIS from "@/http/apis";
import "./home.scss";
import html2canvas from "html2canvas";
import { Toast } from "vant";
import { UAParser } from "ua-parser-js";
import { getUrlKey } from "@/common/common";

export default {
  name: "HomeView",
  data() {
    return {
      livePusher: null,
      listData: [],
      timer: null,
      years: "",
      days: "",
      hours: "",
      minutes: "",
      seconds: "",
      show: false,
      active1: false,
      timer2: null,
      isOs: null, // 设备判断 Android, Mac OS, iOS
      token: "",
      userid: 0,
      expiration: null,
      domainList: ["1bca1ab.com", "2541847.com", "localhost"],
      // domainList: ['1bca1ab.com', '2541847.com'],
      dataObj: {},
      nickName: "",
      NumberPeo: 10678,
      timerNumber: null,
      isShowBtn: true,
      isRn: false,
      isReadyLive: false
    };
  },
  computed: {
    isdomainLive() {
      return this.domainList.includes(window.location.hostname);
    }
  },
  created() {
    if (this.timerNumber) {
      clearInterval(this.timerNumber);
    }
    if (window.localStorage.getItem("dcount")) {
      this.NumberPeo = parseInt(window.localStorage.getItem("dcount"));
    } else {
      this.NumberPeo = parseInt(String(+new Date()).substring(4, 9)) + 10678;
    }
    this.timerNumber = setInterval(() => {
      this.NumberPeo += Math.floor(Math.random() * 11);
      window.localStorage.setItem("dcount", this.NumberPeo);
    }, 1000);

    // if (getUrlKey("userId") != -1 && getUrlKey("userId") != null) {
    //   this.active1 = true;
    // }
    // this.active1 = window.localStorage.getItem("active1");
  },
  mounted() {
    if (window.location.search.indexOf("?isRn=1") != -1) {
      this.isRn = true;
    }
    if (+new Date() > +new Date("2023/7/12 21:00:00")) {
      this.isReadyLive = true;
    } else {
      this.getDate();
    }
    if (window.location.search != "") {
      this.getUrlUserId();
    }
    if (!this.domainList.includes(window.location.hostname)) {
      this.isDive();
    }
    // console.log(JSON.stringify(window.localStorage))
  },
  methods: {
    GetQueryString(name) {
      let reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
      let r = window.location.search.substring(1).match(reg);
      if (r != null) return decodeURIComponent(r[2]);
      return null;
    },
    // async goToData() {
    //   let res = await APIS.getAnchormanInfo({
    //     apiName: "getAnchormanInfo",
    //     memberId: 1,
    //   });
    //   if (res && res.code == "1" && res.data) {
    //     this.listData = res.data;
    //   }

    // },
    async goSendData() {
      let res = await APIS.postRecordAppointmen({
        user_id: this.userid
      });
      console.log("res", res);
      if (res && res.data.code == 200) {
        this.show = true;
        this.active1 = true;
        this.dataObj = res.data.data;
        this.NumberPeo = this.dataObj.iAllNum;
        // window.localStorage.setItem("active1", true);
      } else {
        Toast.fail(res.data.message);
      }
    },
    // 获取url参数
    getUrlUserId() {
      this.userid = getUrlKey("userId");
      this.nickName = getUrlKey("nickName");
      let token11 = getUrlKey("token");
      this.token = token11 && token11.split("?")[0];
      console.log("token", this.token);
      window.localStorage.setItem("token", this.token);
    },
    getDate() {
      if (this.timer) {
        clearInterval(this.timer);
      }
      this.timer = setInterval(() => {
        if (+new Date() > +new Date("2023/7/12 21:00:00")) {
          this.isReadyLive = true;
          clearInterval(this.timer);
        }

        let curDate = new Date();
        let currentTime = curDate.getTime();

        let newDate = new Date("2023/07/12 21:00:00");
        let newTime = newDate.getTime();
        let second = Math.floor((newTime - currentTime) / 1000);
        this.days = Math.floor(second / 86400);
        second = second % 86400;
        this.hours = Math.floor(second / 3600);
        second %= 3600; //  余数代表 剩下的秒数
        this.minutes = Math.floor(second / 60);
        this.seconds = second % 60;
      }, 1000);
    },
    getTwo(n) {
      return n >= 0 && n < 10 ? "0" + n : "" + n;
    },
    // 立即预约
    handDialogClick() {
      console.log("isRn", this.isRn);
      if (this.domainList.includes(window.location.hostname)) {
        // 2.2.8
        // console.log(getUrlKey("userId"))
        // if (getUrlKey("userId") != -1 && getUrlKey("userId") != null) {
        //   this.goSendData();
        //   localStorage.setItem("active1", true);
        // } else {

        this.active1 = true;
        this.dataObj.iNum = this.NumberPeo;
        this.show = true;
        // window.localStorage.setItem("active1", true);

        // 如果是游客状态
        // if (window.localStorage.getItem("tourist")) {

        // } else {
        //   Toast.fail("未登入，请登入!");
        //   window.localStorage.setItem("tourist", true);
        // if (this.isRn) {
        //   // app 是否登录状态
        //   window.postMessage(
        //     JSON.stringify({
        //       type: "onOpenLivePage"
        //     })
        //   );
        // }
        // // 安卓 app 是否登录状态
        // if (
        //   (window.AndroidClient && window?.AndroidClient.onCheckLoginStatus) ||
        //   false
        // ) {
        //   window?.AndroidClient.onCheckLoginStatus();
        // }

        // setTimeout(() => {
        //   if (
        //     (window?.AndroidClient && window?.AndroidClient.onBackToHome) ||
        //     false
        //   ) {
        //     window?.AndroidClient.onBackToHome();
        //   }
        // }, 1000);

        // }
        // }
        this.sensors.track("appbtn");
      } else {
        if (this.isOs == "Android") {
          if (window.confView.CDN1.length > 0) {
            let cndlist = [];
            let andurl = "";
            cndlist = window.confView.CDN1 && window.confView.CDN1.split(",");
            andurl = window.confView.loadSource.replace(
              "@CDN",
              cndlist[Math.floor(Math.random() * 2)]
            );
            window.open(andurl, "_self");
          } else {
            window.open(window.confView.loadSource, "_self");
          }
        }
        if (this.isOs == "iOS") {
          this.$copyText(window.confView.maskAccess).then(function(e) {
            if (window.confView.loadSource) {
              window.open(window.confView.loadSource);
            } else {
              window.open(window.confView.websiteFlutter, "_self");
              setTimeout(() => {
                window.open("//mm.93xup8n1.com/udid2.mobileprovision", "_self");
              }, 3000);
            }
          });
        }
        this.sensors.track("ldybtn");
      }
    },
    handSaveImage() {
      this.isShowBtn = false;

      if (getUrlKey("isRn") == 1) {
        // app 保存图片
        window.postMessage(
          JSON.stringify({
            type: "savePicture"
          })
        );
      }
      // 安卓 app 保存图片
      if ((window.AndroidClient && window.AndroidClient.savePicture) || false) {
        window?.AndroidClient.savePicture();
      }

      // const ImageDiv = this.$refs.ImageContent;
      // html2canvas(ImageDiv).then(res => {
      //   let dataUrl = res.toDataURL('image/jpeg', 1.0);
      //   let a = document.createElement('a');
      //   a.href = dataUrl;
      //   a.download = '分享.png'; // 设置图片名称
      //   a.click();
      // })
    },
    handCopyClick() {
      let url = "yg15.app";
      if (this.GetQueryString("ldyaddress")) {
        url = this.GetQueryString("ldyaddress");
      }
      this.$copyText(url).then(function(e) {
        Toast.success("内容已复制，快去和朋友分享吧");
      });
    },
    // 设备判断
    isDive() {
      const data = UAParser();
      const { browser, device, os } = data;
      this.isOs = os.name;
      if (this.isOs == "Android") {
        this.intScript(
          "https://one555.app/settings/one555.app.and.js?v=612962"
        );
      }
      if (this.isOs == "iOS") {
        this.intScript(
          "https://one555.app/settings/one555.app.ios.js?v=406035"
        );
      }
    },
    intScript(url) {
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.src = url;
      document.getElementsByTagName("head")[0].appendChild(script);
    },

    // 长按事件
    touchStart() {
      clearTimeout(this.timer2);
      this.timer2 = setTimeout(() => {
        // this.handSaveImage();  长按执行事件
      }, 600);
    }
  }
};
</script>
