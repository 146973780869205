import Vue from 'vue'
import App from './App.vue'

import './assets/reset.css'  // 引入基本样式
import 'lib-flexible/flexible'
import 'vant/lib/index.css';
import { Popup } from 'vant';
import VueClipboard from 'vue-clipboard2';
var sensors = require('sa-sdk-javascript');

Vue.prototype.sensors = sensors;
sensors.init({
  server_url: 'https://sc.fyvnrgfq.com/sa?project=MTF01',
  is_track_single_page: true, // 单页面配置，默认开启，若页面中有锚点设计，需要将该配置删除，否则触发锚点会多触发 $pageview 事件
  use_client_time: true,
  send_type: 'beacon',
  heatmap: {
    //是否开启点击图，default 表示开启，自动采集 $WebClick 事件，可以设置 'not_collect' 表示关闭。
    clickmap: 'default',
    //是否开启触达图，not_collect 表示关闭，不会自动采集 $WebStay 事件，可以设置 'default' 表示开启。
    scroll_notice_map: 'default'
  }
});
sensors.quick('autoTrack');

Vue.use(Popup)
Vue.use(VueClipboard)

Vue.config.productionTip = false
// import VConsole from 'vconsole'
// // uat环境显示
// let environment = process.env.VUE_APP_MODE;
// if(environment != 'production'){
//     const vConsole = new VConsole();
//     vConsole.setSwitchPosition(20, 200);
// }

new Vue({
  render: h => h(App)
}).$mount('#app')
