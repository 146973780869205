import { get, post, post2 } from "./axiosConfig";

const APIS = {
  // 获取列表
  getMovieList(data) {
    return get('/api/movieList', data);
  },
  // 立即预约
  postRecordAppointmen(data) {
    return post( data );
  },


  
  getGiftList: (payload = {}) => {
    return post({ ...payload });
  },
 

  hotAnchormanInfoList: (payload = {}) => {
    return post({ ...payload })
  },
  getAnchormanInfo: (payload = {}) => {
    return post2(payload)
  },
  
}

export default APIS;
